<!--
 * @Autor: xin
 * @Date: 2022-07-08 14:37:02
 * @LastEditors: xin
 * @LastEditTime: 2022-11-29 16:36:09
 * @FilePath: \train-pdf-project\src\components\NavBar.vue
 * @Description: 顶部导航条
-->
<template>
  <div class="nav-bar-com">
    <!-- 状态栏占位 -->
    <div class="place"></div>
    <div class="nav-bar-box">
      <div class="nav-bar-left">
        <img src="@/assets/images/left_icon.png" alt="返回" @click="handleBack(1)">
      </div>
      <div class="nav-bar-middle">{{ title }}</div>
      <div class="nav-bar-right">
        <img src="@/assets/images/exit_icon.png" alt="退出" @click="handleBack(2)">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    courseId: "",
    type: {
      type: Number,
      default: 101
    },
    title: {
      type: String,
      default: "PDF文档浏览"
    }
  },

  data () {
    return {

    }
  },

  methods: {
    handleBack (type) {
      this.$emit("handleBack", type)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-bar-com {
  width: 750px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  .place {
    width: 750px;
    height: 0;
    background-color: #FFFFFF;
  }
  .nav-bar-box {
    height: 82px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: 2px solid #EEEEEE;
    background-color: #FFFFFF;
    .nav-bar-left {
      flex: 1;
      text-align: left;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .nav-bar-middle {
      width: 70%;
      text-align: center;
      overflow: hidden;
	    text-overflow: ellipsis;
		  white-space: nowrap;
    }
    .nav-bar-right {
      flex: 1;
      text-align: right;
      img {
        width: 44px;
        height: 44px;
      }
    }
  }
}
</style>