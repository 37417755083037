<!--
 * @Autor: xin
 * @Date: 2022-07-08 11:44:42
 * @LastEditors: xin
 * @LastEditTime: 2022-11-29 16:41:13
 * @FilePath: \train-pdf-project\src\views\home.vue
 * @Description: 
-->
<template>
  <div class="home-page">
    <nav-bar v-if="!isAppletPort" :title="fileInfo.name" :courseId="fileInfo.courseId" :type="type" @handleBack="handleBack"></nav-bar>
    <!-- <nav-bar v-if="!isAppletPort" :sysyemHeight="sysyemHeight" :title="fileInfo.name" :courseId="fileInfo.courseId" :type="type" @handleBack="handleBack"></nav-bar> -->

    <div class="custom-progress" :style="isAppletPort ? `width:${screenWidth}px;` : `top: 43px; width:${screenWidth}px;`"></div>

    <div class="pdf-box-box">
      <div class="pdf-box" ref="pdfBox" :style="`width: ${scale}%;${isAppletPort ? 'margin-top: 0;' : 'margin-top: 43px;'}`">
        <pdf ref="pdf" v-for="i in numPages" :key="i" :src="pdfUrl" :page="i"></pdf>
      </div>
    </div>
    
    <div class="btn-box" v-show="timer">
      <div class="btn-item btn-item-1" @click="handleZoom(1)">+</div>
      <div class="btn-item btn-item-2" @click="handleZoom(2)">-</div>
    </div>

    <div v-if="showLoading" class="loading"></div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import pdf from 'vue-pdf'
import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js' //引入水印依赖
import axios from 'axios'
import qs from 'qs'
export default {
  name: 'HomeView',
  
  components: {
    NavBar,
    pdf
  },

  data () {
    return {
      uid: 0,
      token: "",
      sysyemHeight: 0,
      fileInfo: {},
      isAppletPort: false,

      screenWidth: 100,
      screenWidth2: 0,

      pdfUrl: null,
      numPages: null, // pdf 总页数

      isPageLoading: false,
      isvalidDoc: true, //是否有效的doc链接
      timer: null,

      scale: 100,

      courseScheduleUpdate: 0,
      showLoading: true
    }
  },

  mounted () {
    this.showLoading = true
    this.screenWidth = window.screen.width
    this.screenWidth2 = window.screen.width * 0.01

    let params = this.$route.query
    if (!params.token) return
    this.uid = parseInt(params.uid)
    this.token = params.token
    // 手机系统状态栏高度
    this.sysyemHeight = parseInt(params.sysyemHeight)
    // 文件信息
    this.fileInfo = JSON.parse(params.params)
    document.title = this.fileInfo.name
    // 如果存在isAppletPort，代表从培训小程序入口访问的
    if (params.isAppletPort) {
      this.isAppletPort = true
    } else {
      this.isAppletPort = false
    }

    this.getNumPages( () => {
      setTimeout(() => {
        this.showLoading = false
        this.timer = setInterval(() => {
          let screenWidth = this.screenWidth
          if (screenWidth > 0) {
            this.screenWidth = screenWidth - this.screenWidth2
            if (screenWidth < this.screenWidth2) {
              this.screenWidth = 0
            }
          } else {
            clearInterval(this.timer)
            if (this.fileInfo.percent < 100) {
              this.submitPercent()
            }
          }
        }, 200);
      }, 1000);
    })

  },

  methods: {
    
    getNumPages(callback) {
      this.pdfUrl = pdf.createLoadingTask({ url: this.fileInfo.learningFile, CMapReaderFactory })
      this.pdfUrl.promise.then( pdf => {
        this.numPages = pdf.numPages
        callback && callback()
      }).catch(err => {
        this.isvalidDoc = false
      })
    },

    handleBack (type) {
      if (type === 1) {
        this.$uniAPI.navigateBack()
      } else {
        this.$uniAPI.switchTab({ url: "/pages/index/index" })
      }
    },

    submitPercent () {
      let obj = {
        uid: this.uid,
        learningPlanId: this.fileInfo.learningPlanId,
        learningPlanGroupId: this.fileInfo.learningPlanGroupId,
        learningPlanDataId: this.fileInfo.learningDataId,
        lookLength: 1
      }
      axios.post('/phone/learningPlan/userlearningPlanSchedule', qs.stringify(obj), { headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': this.token, 'Train-Course': this.isAppletPort }}).then( (response) => {
        this.courseScheduleUpdate = response.data.percent
        // 向APP传递的消息信息，必须写在 data 对象中。
        this.$uniAPI.postMessage({ data: { percent: response.data.percent } })
      }).catch(function (error) {
        console.log(error);
      });
    },

    /** 文档放大缩小 */
    handleZoom (type) {
      console.log(this.$refs.pdfBox.style)
      if (type === 1) {
        if (this.scale < 200) {
          let scale = this.scale += 20
          this.$refs.pdfBox.style.width = scale
        }
      } else {
        if (this.scale > 100) {
          let scale = this.scale -= 20
          this.$refs.pdfBox.style.width = scale
        }
      }

    },
  },

  destroyed () {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.home-page {
  width: 750px;
  .custom-progress {
    width: 750px;
    height: 4px;
    position: fixed;
    top: 0;
    z-index: 99;
    transition: .5s;
    background-color: #2979FF;
  }
  .pdf-box-box {
    max-width: 750px;
    // margin-top: 90px;
    // max-height: calc(100vh - 120px);
    overflow: scroll;
    .pdf-box {
      width: 100%;
      height: 100%;
      // margin-top: 92px;
      overflow: hidden;
    }
  }
  .btn-box {
    width: 80px;
    position: fixed;
    bottom: 100px;
    right: 60px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 20px #DDD;
    z-index: 999;
    .btn-item {
      width: 80px;
      line-height: 84px;
      text-align: center;
      color: #333;
      font-size: 40px;
      display: inline-block;
      background-color: #fefefe;
    }
    .btn-item-1 {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: 2px solid #DDD;
    }
    .btn-item-2 {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .loading {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 40%;
    left: 46%;
    border: 4px solid rgba(0,0,0,0.1);
    border-radius: 50%;
    border-left-color: #2979FF;
    animation: loading 1s infinite linear;
  }

  @keyframes loading {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }
}
  
</style>